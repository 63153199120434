/* Pulse */
@keyframes hvr-pulse {
  25% {
    transform: scale(1.05);
  }
  75% {
    transform: scale(0.95);
  }
}
.hvr-pulse {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-pulse:hover,
.hvr-pulse:focus,
.hvr-pulse:active,
.hvr-pulse-active {
  animation-name: hvr-pulse;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

/* Back Pulse */
@keyframes hvr-back-pulse {
  50% {
    fill: rgba(208, 2, 27, 0.75) !important;
  }
}
.hvr-back-pulse svg {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  overflow: hidden;
  transition-duration: 0.5s;
  transition-property: all;
}
.hvr-back-pulse svg:hover,
.hvr-back-pulse svg:focus,
.hvr-back-pulse svg:active {
  animation-name: hvr-back-pulse;
  animation-duration: 1s;
  animation-delay: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  fill: white !important;
}

/* Fade */
.hvr-fade span {
  display: inline-block;
  vertical-align: middle;
  transform: translateZ(0);
  // box-shadow: 0 0 1px rgba(2098D1);
  backface-visibility: hidden;
  overflow: hidden;
  transition-duration: 0.3s;
  opacity: 1;
  transition-property: color, opacity;
}

.hvr-fade-active img.pantry-item-image {
  opacity: 0.8;
}
