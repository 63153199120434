@font-face {
    font-family: 'Argone LC';
    src: url('ArgoneLCBold.eot');
    src: url('ArgoneLCBold.eot?#iefix') format('embedded-opentype'),
        url('ArgoneLCBold.woff2') format('woff2'),
        url('ArgoneLCBold.woff') format('woff'),
        url('ArgoneLCBold.ttf') format('truetype'),
        url('ArgoneLCBold.svg#ArgoneLCBold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Argone LC';
    src: url('ArgoneLC.eot');
    src: url('ArgoneLC.eot?#iefix') format('embedded-opentype'),
        url('ArgoneLC.woff2') format('woff2'),
        url('ArgoneLC.woff') format('woff'),
        url('ArgoneLC.ttf') format('truetype'),
        url('ArgoneLC.svg#ArgoneLC') format('svg');
    font-weight: normal;
    font-style: normal;
}

