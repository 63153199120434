@import "~bulma/sass/utilities/_all.sass";
@import "../styles/variables.scss";

#base-layout {
  &.hero.is-fullheight {
    min-height: auto;
  }
  .navbar {
    height: 60px;
    width: 100%;
  }
  .navbar,
  .navbar-brand {
    justify-content: center;
  }

  .container {
    width: 100%;
  }

  > .container {
    min-height: calc(100vh - 60px);
  }

  .back-btn {
    position: absolute;
    top: 20px;
    left: 15px;
    color: $white;
    @include desktop {
      display: none;
    }
  }
  .navbar-burger span {
    min-height: 2px;
  }
  .hero-head .navbar.is-primary {
    background: $primary;

    @include desktop {
      background: rgba(255, 255, 255, 0.8);
    }

    .logo-white-container {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      left: 0;
      right: 0;
      @include desktop {
        display: none;
      }
    }

    .logo-red-container {
      display: none;
      @include desktop {
        display: block;
        padding: 0.5rem 30px;
      }
    }

    @include desktop {
      &:before {
        content: "";
        background-image: url("../static/assets/banner.png");
        background-repeat: repeat-x;
        position: relative;
        top: 0;
        left: 0;
        height: 61px;
        position: absolute;
        right: 0;
        width: 100%;
        background-size: contain;
        position: absolute;
        right: 0;
        width: 100%;
        @include desktop {
          content: none;
        }
      }
    }
  }

  #navbar-menu {
    &.is-active {
      background: $primary;
      overflow: hidden;
      .navbar-end {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 60px);
      }
      .navbar-item {
        text-align: center;
        color: white;
        font-size: 24px;
        margin-top: 30px;
        font-weight: bold;
        letter-spacing: 2px;
        font-family: "Argone LC";
      }
    }
    .navbar-item {
      @include desktop {
        color: $primary;
        &:hover,
        &:focus,
        &.active {
          &:after {
            content: "";
            position: absolute;
            bottom: 15px;
            left: 0.75rem;
            right: 0.75rem;
            background-color: $primary;
            height: 2px;
          }
        }
      }
    }
  }

  footer {
    display: none;
    @include desktop {
      margin-top: 30px;
      display: block;
      position: relative;
      bottom: 0px;
      width: 100%;
      text-align: center;
      background: #4d4d4d none repeat center top;
      padding: 50px 0 0 0;
    }
    /* Footer */
    .site-info {
      font-family: "CenturyGothicStd", Helvetica, Arial, Verdana, sans-serif;
      text-transform: uppercase;
      font-size: 12px;
      display: block;
      clear: both;
      padding: 20px 0px 53px 0;
      color: #f2f2f2;
    }
    .site-info a {
      text-decoration: none;
      color: #f2f2f2;
    }
    .site-info a:hover {
      color: #ffffff;
      text-decoration: underline;
    }

    #ceres-social {
      text-align: center;
      line-height: 0.7;
      padding-bottom: 21px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    #ceres-social a {
      text-decoration: none;
      transition: all 0.15s ease;
      background: white;
      border-radius: 100px;
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;
      margin: 0px 10px;
    }
    #ceres-social a:hover {
      background: #eee;
    }
    #ceres-social a i {
      font-size: 20px;
      color: #4d4d4d;
    }

    .footer-nav {
      clear: both;
      display: block;
      float: left;
      width: 100%;
    }

    #footer-menu {
      padding: 10px 0;
      line-height: 0;
      margin: 0;
    }
    #footer-menu .sub-menu {
      display: none;
    }
    #footer-menu li:first-child {
      margin-left: 25px;
    }
    @media screen and (min-width: 768px) {
      #footer-menu li:first-child {
        margin-left: 0px;
      }
    }
    #footer-menu li:last-child {
      margin-right: 0px;
    }
    #footer-menu li:hover > a {
      color: #ffffff;
    }
    #footer-menu li {
      margin-left: 0px;
      margin-right: 25px;
      display: inline-block;
      position: relative;
      z-index: auto;
      vertical-align: middle;
    }
    #footer-menu li a {
      padding: 6px 0 7px;
      font-family: "CenturyGothicStd", Helvetica, Arial, Verdana, sans-serif;
      font-weight: normal;
      font-size: 15px;
      color: #dddddd;
      line-height: 26px;
      text-transform: uppercase;
      display: block;
      border: 1px solid transparent;
      text-decoration: none;
      text-align: left;
    }
  }
}
