@import "~bulma/sass/utilities/initial-variables";

// bulma variables
$red: #9e2429;
$grey: #8d9095;

// Update Bulma's global variables
$family-sans-serif: "Open Sans", "CenturyGothicStd", Helvetica, Arial, Verdana, sans-serif;
$grey-dark: $grey;
$grey-light: $grey;
$primary: $red;
$link: $red;
$widescreen-enabled: true;
$fullhd-enabled: true;

$navbar-height: 3.75rem;
$navbar-item-img-max-height: 3.75rem;

$tablet: 768px;

$green: #abad32;
