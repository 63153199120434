@import "./variables";

#nprogress {
  pointer-events: none;
}
#nprogress .bar {
  background: #29d;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1;
  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}
#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: solid 2px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 50%;
  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}
.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}
.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}
@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.pageloader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 80px;
  width: calc(100% - 80px);
  // background-color: $fade-grey;
  z-index: 999998;
  transition: -webkit-transform 0.35s ease-out;
  transition: transform 0.35s ease-out;
  transition: transform 0.35s ease-out, -webkit-transform 0.35s ease-out;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  will-change: transform;
  //Active state
  &.is-active {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  //Fullwidth modifier
  &.is-full {
    width: 100% !important;
    left: 0 !important;
  }
  &.is-demo {
    background-image: linear-gradient(to right, #6a11cb 0%, #2575fc 100%) !important;
  }

  &::after,
  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    border-radius: 100%;
    content: "";
    z-index: 999999;
  }

  &::after {
    margin-top: -100px;
    margin-left: -100px;
    width: 200px;
    height: 200px;
    background-color: rgba(255, 255, 255, 0.5);
    border: 3px solid rgba(255, 255, 255, 0.75);
    -webkit-animation: page-loader-inner 1.5s infinite ease-out;
    animation: page-loader-inner 1.5s infinite ease-out;
  }

  &::before {
    margin-top: -30px;
    margin-left: -30px;
    width: 60px;
    height: 60px;
    background-color: #fff;
    -webkit-animation: page-loader-outer 1.5s infinite ease-in;
    animation: page-loader-outer 1.5s infinite ease-in;
  }
}

/* ==========================================================================
1. Keyframes
========================================================================== */

@-webkit-keyframes page-loader-inner {
  0%,
  40% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes page-loader-inner {
  0%,
  40% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@-webkit-keyframes page-loader-outer {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100%,
  40% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
}

@keyframes page-loader-outer {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100%,
  40% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
}

/* ==========================================================================
2. Infraloader
========================================================================== */

.infraloader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 80px;
  width: calc(100% - 80px);
  background-color: $white;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.35s;
  //Active state
  &.is-active {
    opacity: 1;
    z-index: 999997;
  }
  //Fullwidth modifier
  &.is-full {
    width: 100% !important;
    left: 0 !important;
  }
}
