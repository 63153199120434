@import "~bulma/sass/utilities/_all.sass";
@import "../../styles/variables.scss";

.custom-modal {
  .modal-background {
    background: #fff;
    opacity: 0.7;
  }

  .modal-card-header {
    position: relative;
    width: 100%;
  }

  .modal-card {
    width: auto;
    margin: 15px;
    margin-bottom: 100px;
    padding: 20px;
    background: rgba(168, 46, 67, 0.9);
    position: relative;
    align-items: center;
    max-height: calc(100vh - 150px);
    overflow: visible;
    @include desktop {
      margin: 0;
      width: 900px;
      max-width: 100%;
      padding: 30px;
    }
  }

  .modal-card-header .modal-card-title {
    display: flex;
    justify-content: center;
  }

  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    margin: 0;
    padding: 0;
    transform: scale(1.5);
    z-index: 1;
  }

  .modal-card-body {
    text-align: center;
    color: white;
    overflow: auto;
    padding: 0;
    max-height: 100%;
    background: transparent;
    @include tablet {
      padding: 0;
    }
    @include desktop {
      max-height: 100vh;
      padding: 0px 15px;
      max-width: 600px;
      margin: 0 auto;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      color: white;
    }

    h1 {
      font-size: 1rem;
      line-height: 1.5rem;
      margin: 10px 0px !important;
    }

    h2 {
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 600;
      // margin: 15px 0px !important;
      margin: 0px !important;
    }

    h3 {
      font-family: "Love Jones";
      font-size: 1.5rem;
      line-height: 1.85rem;
      letter-spacing: 2px;
      margin: 10px 0px !important;
    }

    h4 {
      font-weight: 300;
      font-size: 1.125rem;
      line-height: 1.5rem;
      font-family: "Argone LC";
      margin: 10px 0px !important;
    }

    p {
      font-size: 0.85rem;
      line-height: 1.125rem;
      font-weight: 300;
      margin: 10px 0px !important;
      @include desktop {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }

    a {
      color: white;
      text-decoration: underline;
      &:hover,
      &:focus {
        opacity: 0.7;
      }
    }

    img {
      max-height: 80px;
      min-height: 40px;
      width: auto;
      @include desktop {
        max-height: 150px;
        min-height: 40px;
      }
    }
  }
  .modal-card-foot {
    background: transparent;
    border: none;
    text-align: center;
    justify-content: center;
    padding: 0;
    padding-top: 20px;
    .modal-button {
      display: block;
      flex-grow: 1;
      .modal-button-img {
        display: block;
        max-width: 140px;
        @include desktop {
          max-width: 200px;
        }
      }
      .modal-button-active-img {
        display: none;
        height: 60px;
        @include desktop {
          height: auto;
          max-width: 200px;
        }
      }
      &:hover,
      &:focus {
        .modal-button-img {
          display: none;
        }
        .modal-button-active-img {
          display: block;
        }
      }
    }
  }

  .modal-card-terms {
    position: absolute;
    bottom: -70px;
    height: 50px;
    overflow: auto;
    width: 100%;
    z-index: 99999999;
    @include desktop {
      height: 80px;
      bottom: -90px;
    }
    p,
    p > strong {
      font-size: 0.65rem;
      text-align: center;
      color: $primary;
    }
  }
}
