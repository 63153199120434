@import "nprogress/nprogress"; // importing a css file from the nprogress node module
@import "./theme";
@import "./pageloader";
@import "./variables";
@import "./animation";
@import "./grid";

body.no-scroll {
  position: fixed;
  width: 100%;
  height: 100%;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.Toastify__toast--success {
  background: $green;
}

.Toastify__toast--error {
  background-color: #b34a75;
}

.custom-form-page {
  padding-top: 0px;
  min-height: 100vh;

  @include desktop {
    display: flex;
    // align-items: center;
    justify-content: center;
  }
  &:before {
    content: "";
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: url("../static/assets/paper-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  > div {
    z-index: 1;
    position: relative;
  }
  h1 {
    font-family: "Love Jones";
    font-size: 3rem;
    line-height: 3.5rem;
    margin: 30px 0px !important;
    color: $primary;
    text-align: center;
    @include desktop {
      margin-top: 0 !important;
    }
  }

  p {
    color: black;
    font-weight: 600;
    font-size: 0.85rem;
    line-height: 1.25rem;
  }

  .content.terms p,
  .content.terms p strong {
    color: $primary;
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .control {
    position: relative;
    .icon {
      position: absolute;
      top: 5px;
    }
  }
  .field .input {
    background: rgb(217, 217, 217);
    &:hover,
    &:focus,
    &:active,
    &.is-success {
      background: white;
    }
  }

  .field .select {
    background: rgb(217, 217, 217);
    width: 100%;
    height: 50px;
    border-radius: 3px;

    select {
      background: transparent;
      width: 100%;
      height: 100%;
      color: inherit;
    }
    &:hover,
    &:focus,
    &:active,
    &.is-success {
      background: white;
    }

    ~ .icon {
      right: 30px;
    }
  }

  form .field {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }

  form .field-checkbox {
    max-width: 600px;
    border: 1px solid #d3d3d3;
    background: #f9f9f9;
    padding: 10px;

    i {
      display: none !important;
    }
    .checkbox-label {
      color: $black;
      // text-align: center;
      display: block !important;
      p {
        margin-bottom: 10px;
      }
      &:hover,
      &:focus,
      &:active {
        opacity: 0.8;
      }
    }

    .control label.checkbox-label:before,
    .is-checkradio[type="checkbox"].is-white:checked.has-background-color + label::before,
    .is-checkradio[type="checkbox"].is-white:checked.has-background-color + label:before {
      border-color: #d3d3d3 !important;
    }
  }

  .form-button {
    width: auto;
    z-index: 10;
    margin: 30px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    background: none !important;
    background-color: transparent !important;
    border: none;
    height: 80px;
    padding: 0;

    > span {
      height: 100% !important;
    }
    .form-button-image {
      display: block;
      max-width: 200px;
    }
    .form-button-active-image {
      display: none;
      max-width: 200px;
    }
    &:hover,
    &:focus {
      background: transparent;
      .form-button-image {
        display: none;
      }
      .form-button-active-image {
        display: block;
      }
    }
  }

  .visually-hidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
  }
}

$checkbox-radius: $radius !default;
$checkbox-border: 0.1rem solid $grey-lighter !default;
$checkbox-block-background: $white-ter !default;
$checkbox-checked-width: 0.1rem !default;
$checkradio-focus: 1px dotted $grey-light !default;
$checkradio-top-offset: 0rem !default;

@mixin checkbox-size($size) {
  $newSize: $size * 1.5;
  $height: $newSize / 2.5;
  $width: $newSize / 4;

  + label {
    font-size: $size;
    // line-height: $newSize
    padding-left: $size * 2;
    &::before,
    &:before {
      width: $newSize;
      height: $newSize;
    }
    &::after,
    &:after {
      width: $width;
      height: $height;
      top: (($newSize / 2) - ($height / 2)) * 0.9;
      left: $height;
    }
  }

  &.is-block {
    + label {
      &::before,
      &:before {
        width: 1.25 * $size;
        height: 1.25 * $size;
        left: 0.175rem;
        top: 0.175rem;
      }
      &::after,
      &:after {
        top: 0.2rem + $size * 0.125;
        left: 0.2rem + $size * 0.45;
      }
    }
  }

  &.is-rtl {
    + label {
      padding-left: 0;
      padding-right: $size * 2;
      &::after,
      &:after {
        left: auto;
        right: $height;
      }
    }
  }
}

// Use a mixin to define all ratios in the same spot
@mixin radio-size($size) {
  $newSize: $size * 1.5;
  $height: $newSize;
  $width: $newSize;

  + label {
    font-size: $size;
    line-height: $newSize;
    padding-left: $size * 2;
    &::before,
    &:before,
    &::after,
    &:after {
      width: $width;
      height: $height;
    }
  }
  &.is-rtl {
    + label {
      padding-right: $size * 2;
    }
  }
}

.is-checkradio[type="radio"],
.is-checkradio[type="checkbox"] {
  outline: 0;
  user-select: none;
  display: inline-block;
  position: absolute;
  opacity: 0;

  + label {
    position: relative;
    display: initial;
    cursor: pointer;
    vertical-align: middle;
    margin: 0.5em;
    padding: 0.2rem 0.5rem 0.2rem 0;
    border-radius: $checkbox-radius;
    &:first-of-type {
      margin-left: 0;
    }

    &:hover {
      &::before,
      &:before {
        animation-duration: 0.4s;
        animation-fill-mode: both;
        animation-name: hover-color;
      }
    }

    &::before,
    &:before {
      position: absolute;
      left: 0;
      top: $checkradio-top-offset;
      content: "";
      border: $checkbox-border;
    }

    &::after,
    &:after {
      position: absolute;
      display: none;
      content: "";
      top: $checkradio-top-offset;
    }
  }

  &.is-rtl {
    + label {
      margin-right: 0rem;
      margin-left: 0.5rem;
      &::before,
      &:before {
        left: auto;
        right: 0;
      }
    }
  }

  &:focus {
    + label {
      &::before,
      &:before {
        outline: $checkradio-focus;
      }
    }
  }

  &:hover:not([disabled]) {
    + label {
      &::before,
      &:before {
        border-color: $primary !important;
      }
    }
  }

  &:checked {
    + label {
      &::before,
      &:before {
        border: $checkbox-border;
      }
    }
    &[disabled] {
      cursor: not-allowed;
      + label {
        opacity: 0.5;
      }
    }
    + label {
      &::before,
      &:before {
        animation-name: none;
      }
      &::after,
      &:after {
        display: inline-block;
      }
    }
  }

  &[disabled] {
    cursor: not-allowed;
    + label {
      opacity: 0.5;
      cursor: not-allowed;
      &:hover,
      &:before,
      &::before,
      &::after,
      &:after {
        cursor: not-allowed;
      }
    }

    &:hover {
      cursor: not-allowed;
      &::before,
      &:before {
        animation-name: none;
      }
    }

    &::before,
    &:before {
      cursor: not-allowed;
    }

    &::after,
    &:after {
      cursor: not-allowed;
    }
  }

  &.has-no-border {
    + label {
      &::before,
      &:before {
        border: none !important;
      }
    }
  }

  &.is-block {
    display: none !important;
    + label {
      width: 100% !important;
      background: $checkbox-block-background;
      color: findColorInvert($checkbox-block-background);
      padding-right: 0.75em;
    }
    &:hover:not([disabled]) {
      + label {
        background: darken($checkbox-block-background, 5%);
      }
    }
  }
}

.is-checkradio[type="checkbox"] {
  + label {
    &::before,
    &:before {
      border-radius: $checkbox-radius;
    }

    &::after,
    &:after {
      box-sizing: border-box;
      transform: translateY($checkradio-top-offset) rotate(45deg);
      border-width: $checkbox-checked-width;
      border-style: solid;
      border-color: $primary;
      border-top: 0;
      border-left: 0;
    }
  }

  &.is-circle {
    + label {
      &::before,
      &:before {
        border-radius: 50%;
      }
    }
  }

  @include checkbox-size($size-normal);
  &.is-small {
    @include checkbox-size($size-small);
  }
  &.is-medium {
    @include checkbox-size($size-medium);
  }
  &.is-large {
    @include checkbox-size($size-large);
  }

  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);
    &.is-#{$name} {
      &.has-background-color {
        + label {
          &::before,
          &:before {
            border-color: transparent !important;
            background-color: $color !important;
          }
        }
      }
      &:hover:not([disabled]) {
        + label {
          &::before,
          &:before {
            border-color: $color !important;
          }
        }
      }
      &:checked {
        + label {
          &::after,
          &:after {
            border-color: $color !important;
          }
        }
        &.has-background-color {
          + label {
            &::before,
            &:before {
              border-color: transparent !important;
              background-color: $color !important;
            }
            &::after,
            &:after {
              border-color: $color-invert !important;
              background-color: $color !important;
            }
          }
        }
      }
      &.is-block {
        &:hover:not([disabled]) {
          + label {
            &::after,
            &:after,
            &::before,
            &:before {
              border-color: $color !important;
            }
          }
        }
        &:checked {
          + label {
            color: $color-invert;
            border-color: $color !important;
            background: $color;
            &::after,
            &:after {
              border-color: $color-invert !important;
            }
          }
          &:hover:not([disabled]) {
            + label {
              background: darken($color, 5%);
              &::after,
              &:after,
              &::before,
              &:before {
                border-color: darken($color-invert, 5%) !important;
              }
            }
          }
        }
      }
    }
  }

  &:indeterminate {
    + label {
      &::after,
      &:after {
        display: inline-block;
        transform: rotate(90deg);
        border-bottom: none;
      }
    }
    @each $name, $pair in $colors {
      $color: nth($pair, 1);
      $color-invert: nth($pair, 2);
      &.is-#{$name} {
        + label {
          &::after,
          &:after {
            border-color: $color;
          }
        }
      }
    }
  }
}

.is-checkradio[type="radio"] {
  + label {
    &::before,
    &:before {
      border-radius: 50%;
    }
    &::after,
    &:after {
      border-radius: 50%;
      background: $primary;
      left: 0;
      transform: scale(0.5);
    }
  }

  &:checked {
    &.has-background-color {
      + label {
        &::before,
        &:before {
          border-color: $text !important;
          background-color: $text !important;
        }
        &::after,
        &:after {
          border-color: $text !important;
          background-color: $text !important;
        }
      }
    }
  }

  &.is-rtl {
    + label {
      padding-left: 0;
      &::after,
      &:after {
        left: auto;
        right: 0;
      }
    }
  }

  @include radio-size($size-normal);
  &.is-small {
    @include radio-size($size-small);
  }
  &.is-medium {
    @include radio-size($size-medium);
  }
  &.is-large {
    @include radio-size($size-large);
  }

  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);
    &.is-#{$name} {
      &.has-background-color {
        + label {
          &::before,
          &:before {
            border-color: $color !important;
            background-color: $color !important;
          }
        }
      }
      &:hover:not([disabled]) {
        + label {
          &::before,
          &:before {
            border-color: $color !important;
          }
        }
      }
      &:checked {
        + label {
          &::after,
          &:after {
            border-color: $color !important;
            background-color: $color !important;
          }
        }
        &.has-background-color {
          + label {
            &::before,
            &:before {
              border-color: $color !important;
              background-color: $color !important;
            }
            &::after,
            &:after {
              border-color: $color-invert !important;
              background-color: $color-invert !important;
            }
          }
        }
      }
    }
  }
}
