@import "~bulma/sass/utilities/_all.sass";
@import "../../styles/variables.scss";

.confirm-modal {
  .modal-background {
    background: #fff;
    opacity: 0.9;
  }
  .modal-close {
    position: absolute;
    top: 0px;
    right: 10px;
    font-size: 24px;
    margin: 0;
    padding: 0;
    transform: scale(1.5);
  }

  .modal-content {
    background: rgb(168, 46, 67);
    padding: 60px 20px 40px 20px;
    margin: 15px;
    max-width: 90%;
    color: $white;
    opacity: 0.9;
    text-align: center;
    color: white;
    overflow: auto;
    @include desktop {
      margin: 0px;
      max-width: 400px;
      padding: 60px 50px 40px 50px;
    }
    h3 {
      color: $white;
      margin: 0 auto;
      @include desktop {
        max-width: 200px;
      }
    }

    .modal-buttons {
      margin-top: 50px;
      display: flex;
      justify-content: space-between;
      a {
        color: $green;
        font-family: "Argone LC";
        font-size: 2.5rem;
        font-weight: bold;
        text-transform: uppercase;
        &:hover,
        &:focus {
          opacity: 0.9;
        }
      }
    }
  }

  .modal-close {
    top: 10px;
    right: 10px;
  }
}
